import React, { useState } from "react"
import "./Team.scss"
import LearnMore from "../../shared/LearnMore"
import TeamMembers from "../../../../static/content/teamMembers.json"
import Modal from "../../shared/Modal/Modal"
import TeamModalBody from "./TeamModalBody/TeamModalBody"
import { getUserAvatar } from "./utils"

const Team = () => {
  const [activeItem, setActiveItem] = useState(null)

  return (
    <div className="team" id="MeettheTeam">
      <div className="section-title">Meet the Team</div>

      <div className="team-members">
        {TeamMembers.map(item => {
          return (
            <div
              className="team-member border-gradient border-gradient-green"
              onClick={() => setActiveItem(item)}
            >
              <div
                style={{
                  backgroundImage: `url(${getUserAvatar(item.img)})`,
                  backgroundPosition: item.imgAlign,
                }}
                className="avatar"
              ></div>
              <div className="name">{item.name}</div>
              <div className="role">{item.role}</div>

              <LearnMore text="Read More" />
            </div>
          )
        })}
      </div>
      <Modal
        className="team-modal"
        setOpen={() => setActiveItem(null)}
        isOpen={Boolean(activeItem)}
      >
        <TeamModalBody
          member={activeItem}
          onClose={() => setActiveItem(null)}
        />
      </Modal>
    </div>
  )
}

export default Team
