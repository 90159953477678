import React from "react"
import "./TeamModalBody.scss"
import CloseIcon from "../../../shared/CloseIcon/CloseIcon"
import { getUserAvatar } from "../utils"

const TeamModalBody = ({ member, onClose }) => (
  <div>
    <div className="TeamModalBody">
      <CloseIcon onClose={onClose} />
      <div
        className="image"
        style={{
          backgroundImage: `url(${getUserAvatar(member.img)})`,
          backgroundPosition: member.imgAlign,
        }}
      ></div>
      <div className="content">
        <div className="name">{member?.name}</div>
        <div className="role">{member?.role}</div>
        <div className="bio">{member?.bio}</div>
      </div>
    </div>
  </div>
)
export default TeamModalBody
