import React from "react"
import aboutImage from "../../../images/about.png"
import "./WhoWeAre.scss"
const WhoWeAre = () => (
  <div className="WhoWeAre" id="WhoWeAre">
    <div>
      <div className="AboutTitle">Who We Are</div>
      <div className="CompanyDescription">
        Foothill Technology Solutions is an International Software development
        company with Offices in the USA and Palestine. We provide an array of
        advanced development and consulting services with the highest standards,
        along with an in-house engineering function added as a direct expansion
        to our clients’ team.
        <br />
        <br />
        FTS has always been dedicated to deliver the most advanced technology
        solutions, guaranteed to meet the needs of the clients. We have achieved
        many successes and developed core projects throughout the years with a
        diverse range of clients, fostering local talent and creating career
        opportunities in Palestine, in service of both Palestine and the global
        market.
      </div>
    </div>
    <div>
      <img className="AboutImage" src={aboutImage} alt="" />
    </div>
  </div>
)

export default WhoWeAre
