import faisal from "../../../images/team/faisal.png"
import ramsey from "../../../images/team/ramzy.jpg"
import randa from "../../../images/team/randa.jpg"
import abdallah from "../../../images/team/abdallah.jpg"

export const getUserAvatar = name => {
  switch (name) {
    case "faisal":
      return faisal
    case "ramsey":
      return ramsey
    case "abdallah":
      return abdallah
    case "randa":
      return randa
  }
}
