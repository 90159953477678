import React from "react"
import CoreValues from "../../../images/whyFoothill/core-values.svg"
import EngagementModel from "../../../images/whyFoothill/engagement-model.svg"
import OurMission from "../../../images/whyFoothill/our-mission.svg"
import Experience from "../../../images/whyFoothill/expereince.svg"

import "./WhyFoothill.scss"
import Container from "@material-ui/core/Container"

const WhyFoothill = () => {
  return (
    <Container>
      <div className="why-foothill" id="OurAdvantages">
        <div className="block">
          <div className="block-icon">
            <img src={OurMission} />
          </div>
          <div className="block-details">
            <div className="block-details-header">Our Mission</div>
            <div className="block-details-content">
              To develop our region and our local talent through
              programs/initiatives – such as internships, mentorship programs,
              workshops, and part-time employment - that support Palestinian
              universities and startups by fostering local talent and create
              career opportunities in Palestine, in service of both Palestine
              and the global market.
            </div>
          </div>
        </div>
        <div className="block">
          <div className="block-details">
            <div className="block-details-header">Core Values</div>
            <div className="block-details-content">
              We’re committed to our employees the same way our employees are
              committed to us. This is reflected in our core values of providing
              employment equality, excellent working conditions, stock options
              and the right career path for our employees to develop and grow.
            </div>
          </div>
          <div className="block-icon">
            <img src={CoreValues} />
          </div>
        </div>
        <div className="block">
          <div className="block-icon">
            <img src={EngagementModel} />
          </div>
          <div className="block-details">
            <div className="block-details-header">Engagement Model</div>
            <div className="block-details-content">
              Our dedicated engineers serve clients worldwide, delivering
              innovative solutions to improve performance. We are dedicated to
              helping the client allocate the right resources to each project,
              and making sure milestones are met throughout our journey
              together.
            </div>
          </div>
        </div>
        <div className="block">
          <div className="block-details">
            <div className="block-details-header">Experience</div>
            <div className="block-details-content">
              We’ve been delivering the most up-to-date development solutions
              for clients worldwide, for nearly a decade. We have been
              successful in building a scalable business through ingrown talent.
              We achieve this by providing fresh graduates with the tech
              background intensive 4 months training program before we kickoff a
              team.
            </div>
          </div>
          <div className="block-icon">
            <img src={Experience} />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default WhyFoothill
