import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import WhoWeAre from "../components/About/WhoWeAre"
import Team from "../components/About/Team"
import WhyFoothill from "../components/About/WhyFoothill"
const SecondPage = () => (
  <Layout currentPage="about">
    <SEO title="About" />
    <WhoWeAre />
    <Team />
    <WhyFoothill />
  </Layout>
)

export default SecondPage
